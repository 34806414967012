<template>
  <div class="page-wrap">
    <div class="header">
      <div class="main">
        <div class="text-center">精简咖啡品鉴</div>
        <div class="logo-container">
          <img class="logo" src="/static/assets/logo1.png" alt="Logo 1">
          <span class="cross">X</span>
          <img class="logo" src="/static/assets/logo2.png" alt="Logo 2">
        </div>
      </div>
    </div>
    <div class="content">
      <div class="question-number">
        <div v-for="(_, index) in evaluations" :class="{ 'number-item': true, active: index === evalIndex }"
             @click="setEvaluation(index)" :key="index">
          {{ index + 1 }}
        </div>
      </div>
      <div class="question-area" v-if="Object.keys(evaluationSection).length > 0">
        <div v-for="(qItem, i) in evalSection[activeIndex]?.questions || []" :key="`question_${i}`" class="item">
          <div class="item-title" :style="{ whiteSpace: 'pre-wrap' }"
               v-html="qItem.question_id + '.' + decodeURIComponent(qItem.text.replace(/\\n/g, '<br>'))">
          </div>

          <!-- 循环渲染 options -->
          <div v-for="(oItem, oIndex) in qItem.options" :key="`option_${i}_${oIndex}`">
            <!-- 展示 options 的 text -->
            <div :class="{ 'item-sub-title': true, required: oItem.text && oItem.question_type === 0 }"
                 v-html="oItem.text ? decodeURIComponent(oItem.text.replace(/\\n/g, '<br>')) : ''"/>

            <!-- 根据 dimensions 的 question_type 来渲染 -->
            <div v-for="(dim, dIndex) in oItem.dimensions" :key="`dimension_${i}_${oIndex}_${dIndex}`"
                 class="item-content">
              <!-- 根据 question_type 判断渲染方式 -->
              <div v-if="oItem.question_type === 0">
                <van-radio-group v-model="oItem.answer" @change="logSelection('radio', dim.dimension_id)">
                  <van-radio :name="dim.dimension_id" :aria-checked="oItem.answer === dim.dimension_id"
                             style="margin-bottom: 0.2rem;" :checked-color="color" :disabled="haveEvaluation.includes(evalIndex)">
                    {{ dim.name }}
                  </van-radio>
                </van-radio-group>
              </div>
              <div v-else-if="oItem.question_type === 1">
                <van-checkbox-group v-model="oItem.answer" @change="logSelection('checkbox', dim.dimension_id)">
                  <van-checkbox :name="dim.dimension_id" :aria-checked="oItem.answer === dim.dimension_id"
                                style="margin-bottom: 0.2rem;" shape="square" :checked-color="color"
                                :disabled="haveEvaluation.includes(evalIndex)">
                    {{ dim.name }}
                  </van-checkbox>
                </van-checkbox-group>
              </div>
              <div v-else-if="oItem.question_type === 2">
                <van-field v-model="oItem.answer" type="textarea" style="border: 1px solid #eee"
                           :disabled="haveEvaluation.includes(evalIndex)" @change="logSelection('textarea', oItem.option_id)"
                           label=""/>
              </div>
            </div>
          </div>
        </div>
        <div class="item" v-if="evalIndex === evaluations.length - 1">
          <van-button type="info"
                      style="width: 100%; border-radius: 0.2rem; background-color: #6F4E37; border-color: #6F4E37;"
                      :disabled="haveEvaluation.includes(evalIndex)" @click="handsIn">
            完成
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
import API from "@/api";

export default {
  name: "QuestionIndex",
  components: {},
  data() {
    return {
      haveEvaluation: [],
      evalIndex: 0,
      activeIndex: 0,
      sectionList: [],
      evalSection: [],
      options: [],
      dimensions: [],
      color: '#6F4E37',
      user_id: '',
      evaluations: [],
      evaluationSection: {}
    };
  },

  /**
   * 在组件创建阶段获取问题详情
   * 通过API请求获取问题的详细信息，并对获取到的数据进行处理
   * 主要包括初始化问题的答案、设置需要评估的部分、设置用户ID、设置评估信息等
   */
  created() {
    // 调用API获取问题详情，参数为路由查询到的id
    API.getQuestionDetail({
      batch_id: this.$route.query.id
    }).then(res => {
      // 检查API返回的状态码，如果为1则表示成功
      if (res.code === 1) {
        localStorage.setItem("question_sections", JSON.stringify(res?.data?.sections || []))
        // 初始化问题列表，从API响应中获取sections，如果没有则为空数组
        this.sectionList = res?.data?.sections || []

        // 遍历sectionList，对每个问题进行初始化
        this.sectionList.forEach(section => {
          // 遍历每个问题，根据问题类型设置初始答案
          section.questions.forEach(question => {
            question.options.forEach(option => {
              // 如果问题是单选或判断题类型，初始答案为null
              if (option.question_type === 0 || option.question_type === 1) {
                question.answer = null
              } else if (option.question_type === 2) {
                // 如果问题是简答题类型，初始答案为空字符串
                question.answer = ''
              }
            })
          })
        })

        // 复制sectionList到evalSection，用于评估
        this.evalSection = [...this.sectionList]

        // 设置用户ID，从API响应中获取
        this.user_id = res?.data?.batch?.user_id

        // 设置评估信息，从API响应中获取evaluations，如果没有则为空数组
        this.evaluations = res?.data?.batch?.evaluations || []

        // 根据evaluations生成evaluationSection，用于存储需要评估的部分
        this.evaluationSection = this.evaluations.reduce((res, item) => ({
          ...res,
          [item]: this.sectionList
        }), {})
        // console.log(this.evaluationSection)
        // 开始评估
        this.startEvalation()
      }
    })
  },
  methods: {
    clearKeysWithPrefix(prefix) {
      const keysToDelete = [];

      // 获取所有键
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith(prefix)) {
          keysToDelete.push(key);
        }
      }

      // 删除所有匹配的键
      keysToDelete.forEach(key => {
        localStorage.removeItem(key);
      });
    },
    logSelection(type, dimensionId) {
      type = null
      dimensionId = 0
      return dimensionId
      // console.log(`${type === 'radio' ? '单选' : type === 'checkbox' ? '多选' : '填空'} 选中了维度 ID: ${dimensionId}`);
      //
      // // 遍历当前测评问卷的所有选项，展示选中状态
      // this.evalSection.forEach((section, sectionIndex) => {
      //   section.questions.forEach((question, questionIndex) => {
      //     question.options.forEach((option, optionIndex) => {
      //       const selectedState = option.answer;
      //       console.log(`问卷部分: ${sectionIndex + 1}, 问题: ${questionIndex + 1}, 选项: ${optionIndex + 1}, 选中状态:`, selectedState);
      //     });
      //   });
      // });
    },

    startEvalation() {
      API.startevaluation({
        eval_id: this.evaluations[this.evalIndex]
      })
    },
    // 设置测评选中状态的函数
    setEvaluation(i) {
      // 首先保存当前测评的选中状态
      const currentEvalId = this.evaluations[this.evalIndex];
      if (currentEvalId) {
        // 在 evaluationSection 中按 eval_id 保存状态，并确保包含 section_id
        this.evaluationSection[currentEvalId] = JSON.parse(JSON.stringify(this.evalSection));
        // console.log(`保存了测评 ${currentEvalId} 的状态:`);
        // console.log(JSON.stringify(this.evaluationSection[currentEvalId], null, 2)); // 打印详细的保存状态

        // 将状态保存到 LocalStorage
        localStorage.setItem(`evaluation_${currentEvalId}`, JSON.stringify(this.evaluationSection[currentEvalId]));
      }

      // 切换到新的测评问卷
      this.evalIndex = i;
      this.activeIndex = 0;

      // 加载新的测评问卷状态
      const newEvalId = this.evaluations[i];
      const savedState = localStorage.getItem(`evaluation_${newEvalId}`);
      if (savedState) {
        this.evalSection = JSON.parse(savedState);
        // console.log(`从LocalStorage加载了测评 ${newEvalId} 的状态:`);
      } else {
        this.evalSection = JSON.parse(JSON.stringify(JSON.parse(localStorage.getItem("question_sections") || "[]")));
        // console.log(`加载了新的默认状态（无先前保存状态）:`);
      }
      API.startevaluation({
        eval_id: newEvalId
      })
      // console.log(JSON.stringify(this.evalSection, null, 2)); // 打印详细的加载状态
    },
    /**
     * 提交当前问卷部分的答案
     * 检查并处理当前活跃部分的未回答问题，然后根据是否还有更多部分来决定是跳转到下一部分还是提交整个问卷
     */
    handsIn() {
      const currentEvalId = this.evaluations[this.evalIndex];
      if (currentEvalId) {
        // 在 evaluationSection 中按 eval_id 保存状态，并确保包含 section_id
        this.evaluationSection[currentEvalId] = JSON.parse(JSON.stringify(this.evalSection));
        // console.log(`保存了测评 ${currentEvalId} 的状态:`);
        // console.log(JSON.stringify(this.evaluationSection[currentEvalId], null, 2)); // 打印详细的保存状态

        // 将状态保存到 LocalStorage
        localStorage.setItem(`evaluation_${currentEvalId}`, JSON.stringify(this.evaluationSection[currentEvalId]));
      }

      // 循环遍历每一份试卷
      for (let index = 0; index < this.evaluations.length; index++) {
        const evalId = this.evaluations[index];
        // console.log('evalId:', evalId, 'index:', index, 'this.evaluations.length', this.evaluations.length);


        // 首先尝试从内存中获取当前评估的状态
        let currentEvalSection = this.evaluationSection[evalId] || JSON.parse(localStorage.getItem(`evaluation_${evalId}`) || '[]');
        // console.log(`处理试卷 ${evalId}:`, currentEvalSection);
        if (!currentEvalSection || currentEvalSection.length === 0) {
          // console.warn(`试卷 ${evalId} 没有找到任何答案数据`);
          return;
        }

        // 整理当前试卷的所有问题，准备提交
        const questions = currentEvalSection.flatMap(section =>
            section.questions.map(item => ({...item, section_id: section.section_id}))
        );

        // console.log('questions:', questions);

        // 过滤出当前试卷中未回答的必答题
        let noAnswer = questions.reduce((acc, qItem) => {
          return acc + qItem.options.filter(option => (option.question_type === 0) && !option.answer).length;
        }, 0);
        noAnswer--
        // console.log('noAnswer:', noAnswer);
        // 如果存在未回答的必答题，则提示用户
        if (noAnswer >= 0) {
          Toast(`请检查卷 ${index + 1} 的必答题目是否填写完成`);
          return;
        }
        // console.log('noAnswer:', noAnswer);
        // 根据问题类型整理答案数据
        const answers = questions.flatMap(qItem => {
          const {question_id, section_id} = qItem;
          return qItem.options.map(option => {
            if ([0, 1].includes(option.question_type)) {
              // 遍历 options 中的 dimensions，并且提交 dimension_id 和 option_id
              return {
                question_id,
                section_id,
                question_type: option.question_type,
                option_id: option.option_id,  // 提交 option 的 ID
                dimension_ids: option.answer || []// 提交该 option 相关的所有 dimension_id 选中的选项
              };
            } else {
              // 提交填空题的答案
              return {
                question_id,
                section_id,
                question_type: option.question_type,
                option_id: option.option_id,  // 提交 option 的 ID
                dimension_ids: option.dimensions?.map(dim => dim.dimension_id) || [], // 获取所有的 dimension_id
                answer_text: option.answer || "" // 填空题的答案
              };
            }
          });
        });


        // 构造提交参数
        const params = {
          batch_id: this.$route.query.id,
          user_id: this.user_id,
          eval_id: evalId,
          answers
        };

        // console.log('提交参数:', params);

        // 提交答案，并处理结果
        API.handsInAnswer(params).then(res => {
          if (index === this.evaluations.length - 1) {
            if (res.code === 1) {
              Toast(`提交成功`);
              // 所有试卷提交完成后，跳转到结果页面
              this.$router.push({
                path: "/Result"
              });
            } else if (res.code === -1) {
              Toast(res.message);
            }


          }

        });
      }


    }
  },
  mounted() {
    // 在页面初始化时清除缓存
    this.clearKeysWithPrefix('evaluation_');
    localStorage.removeItem(`question_sections`);
  },
};
</script>

<style lang="less" scoped>@import url("./index.less");</style>
