// 导入Vue实例
import Vue from 'vue'
// 导入Vue Router实例
import Router from 'vue-router'
// 导入首页组件
import Index from '@/pages/index/index.vue'
// 导入问题页面组件
import Question from '@/pages/question/index.vue'
// 导入结果页面组件
import Result from '@/pages/result/index.vue'

// 使用Vue Router插件
Vue.use(Router)

// 导出默认的路由配置对象
export default new Router({
  // 启用HTML5历史模式
  mode: "hash",
  // 设置路由的基础路径
  base: "/",
  // 配置路由规则
  routes: [
    // 首页路由规则
    {
      path: '/Index',
      component: Index,
      meta: {
        title: '咖啡评测'
      }
    },
    // 问题页面路由规则
    {
      path: '/Question',
      component: Question,
      meta: {
        title: '咖啡评测中'
      }
    },
    // 结果页面路由规则
    {
      path: '/Result',
      component: Result,
      meta: {
        title: '杯测提交'
      }
    }
  ],
})
