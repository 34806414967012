<template>
  <div class="page-wrap">
    <div class="header">
      <div class="back" ></div>
      杯测结果
    </div>
    <div class="result">
      <van-icon name="checked" style="color: green;"/>
      测评完成
    </div>

    <van-button  class="back-button" @click="goBack">
      返回首页
    </van-button>
  </div>
</template>

<script>
export default {
  name: "QuestionIndex",
  components: {},
  data() {
    return {};
  },
  created() {

  },
  mounted() {

  },
  methods: {
    goBack() {
      this.$router.push('/Index');
    }
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");

.page-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.header .back {
  cursor: pointer;
  color: blue;
  flex-shrink: 0; /* 防止收缩 */
  white-space: nowrap; /* 防止换行 */
  margin-left: 16px; /* 左侧间隔 */
}

.result {
  flex-grow: 1; /* 使结果部分占据中间的所有空间 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.back-button {
  width: 100%;
  border-radius: 0.2rem;
  //display: inline-block;
  padding: 10px 20px;
  margin: 20px 0;
  background-color: #6F4E37; /* 咖啡色 */
  border-color: #6F4E37;
  color: white;
  //cursor: pointer;
  //text-decoration: none;
  font-size: 14px; /* 缩小文字 */
  align-self: center; /* 在水平居中 */
}
</style>
