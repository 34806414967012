// 导入axios库，用于发起HTTP请求
import axios from 'axios'

// 创建一个axios实例
const instance = axios.create({
    // 设置请求头，指定Content-Type为JSON格式
    headers: {
        "Content-Type": "application/json; charset=UTF-8"
    },
    // 设置请求的基础URL，从环境变量中获取
    baseURL: process.env.VUE_APP_BASE_URL,
    // 设置请求的超时时间
    timeout: 10000,
    // 允许携带跨域请求的凭证，如cookies
    withCredentials: true
})

// 添加响应拦截器，对请求的响应进行处理
// 添加响应拦截器
instance.interceptors.response.use(
    // 成功的响应处理
    response => {
        // 当响应状态码为200时，进一步判断响应数据
        if (response.status === 200) {
            // 如果响应数据中的code也为200，打印成功信息，并对数据进行加工
            if (response.data && response.data.code === 200) {
                console.log('成功')
                // 对响应数据进行加工，修改value值
                response.data.value = '我是返回成功' // 在请求成功后可以对返回的数据进行处理，再返回到前台
            } else {
                // 如果响应数据中的code不为200，打印登录提示信息
                console.log('返回到登录...')
            }
        }
        // 返回处理后的响应数据
        return response.data;
    },
    // 失败的响应处理
    error => {
        // 如果有错误响应，返回错误数据，并拒绝承诺
        return Promise.reject(error.response.data); // 返回接口返回的错误信息
    }
)

// 导出创建的axios实例
export default instance;
