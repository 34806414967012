// 导入Vue框架
import Vue from 'vue'
// 导入App组件
import App from './App.vue'
// 导入路由管理器
import router from './router'
// 导入Axios库，用于HTTP请求
import Axios from './axios/index.js'
// 导入VantUI库，用于UI组件
import Vant from 'vant'
// 引入normalize.css，用于CSS标准化
import 'normalize.css'
// 引入VantUI的CSS文件
import 'vant/lib/index.css'
// 注释掉的VConsole导入，用于开发时调试
// import VConsole from 'vconsole'
// 引入rem.js，用于适配不同屏幕尺寸
import '../public/rem.js'

// 关闭Vue的生产提示
Vue.config.productionTip = false

// 将Axios挂载到Vue原型上，使得所有组件都能访问
Vue.prototype.$axios = Axios
// 创建一个Vue实例作为事件总线
Vue.prototype.$EventBus = new Vue()

// 使用VantUI库
Vue.use(Vant)

// 在路由切换前设置文档标题
router.beforeEach((to, from, next) => {
    // 如果当前路由有title元数据，则设置文档标题
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // 继续进行路由切换
    next();
})

// 创建Vue实例并挂载到#app元素上
new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app')

// 页面加载完成后动态加载字体文件
// 最后再加载字体文件
window.onload = () => {
    let link = document.createElement("link");
    link.rel = "stylesheet";
    // 根据环境变量决定字体文件的路径
    link.href = "/font/font.css";
    document.head.append(link);
}
