<template>
  <div class="page-wrap">
    <div class="header">创建杯测表</div>
    <div class="form-container">
      <div class="item">
        <van-cell is-link required title="选择样品数量" :value="value" @click="show = true" />
      </div>
      <div class="item">
        <van-field
          v-model="name"
          required
          label="姓名"
          @change="nameError = ''"
          placeholder="请输入姓名"
          :error-message="nameError"
        />
      </div>
      <div class="item">
        <van-field
          v-model="no"
          required
          label="测评编号"
          @change="noError = ''"
          placeholder="请输入测评编号"
          :error-message="noError"
        />
      </div>
      <div class="item">
        <van-button type="info" style="width: 100%; border-radius: 0.2rem; background-color: #6F4E37; border-color: #6F4E37;" @click="createForm">进入杯测</van-button>
      </div>
    </div>
    <van-action-sheet v-model="show" :actions="options" @select="onSelect" />
  </div>
</template>

<script>
import { Toast } from "vant";
import API from "@/api";
export default {
  name: "QuestionIndex",
  components: {},
  data() {
    return {
      show: false,
      options: [],
      value: 1,
      name: "",
      no: "",
      nameError: "",
      noError: "",
    };
  },
  created() {
    this.options = Array.from({ length: 40 }).map((_, index) => ({name: index + 1}))
  },
  methods: {
    onSelect(val) {
      console.log(val)
      this.value = val.name
      this.show = false
    },
    createForm() {
      if (!this.name) {
        this.nameError = "请输入您的姓名"
      }
      if (!this.no) {
        this.noError = "请输入测评编号"
      }
      if (this.nameError || this.noError) {
        return
      }
      API.createForm({
        username: this.name.toString(),
        batch_name: this.no.toString(),
        survey_count: this.value
      }).then(res => {
        if (res.code === 1) {
          Toast("操作成功，请开始杯测")
          this.$router.push({
            path: "/Question",
            query: {
              id: res?.data?.batch_id
            }
          })
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
