import Axios from '@/axios'

/**
 * 评价模块的HTTP请求封装。
 * 提供了创建评价批次、获取题目详情、开始评价、提交答案等操作的接口调用方法。
 */
export default {
    /**
     * 创建评价批次。
     * @param {Object} data - 创建评价批次所需的数据。
     * @returns {Promise} 返回一个Axios请求的Promise对象。
     */
    createForm(data) {
        return Axios({
            url: '/create_evaluation_batch',
            method: 'post',
            data
        })
    },

    /**
     * 获取题目详情。
     * @param {Object} data - 获取题目详情所需的数据。
     * @returns {Promise} 返回一个Axios请求的Promise对象。
     */
    getQuestionDetail(data) {
        return Axios({
            url: '/evaluation',
            method: 'post',
            data
        })
    },

    /**
     * 开始评价。
     * @param {Object} data - 开始评价所需的数据。
     * @returns {Promise} 返回一个Axios请求的Promise对象。
     */
    startevaluation(data) {
        return Axios({
            url: '/evaluation_start',
            method: 'post',
            data
        })
    },

    /**
     * 提交答案。
     * @param {Object} data - 提交答案所需的数据。
     * @returns {Promise} 返回一个Axios请求的Promise对象。
     */
    handsInAnswer(data) {
        return Axios({
            url: '/submit_answers',
            method: 'post',
            data
        })
    }
}
