<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    // 定义组件名称
    name: 'App',
    created() {
        // 在组件创建时，打印当前路由信息到控制台
        console.log(this.$route)
        // 如果当前路由是根路由，则重定向到Index页面
        if (this.$route.path === "/") {
            this.$router.push({ path: "/Index" })
        }
    }
}
</script>

<style>
/* 为mint-ui的加载动画组件设置样式，确保其位于最顶层 */
.mint-indicator-wrapper {
    z-index: 9999;
}
/* 为mint-ui的加载动画组件的遮罩层设置样式，确保其位于加载动画下方 */
.mint-indicator-mask {
    z-index: 9998;
}
</style>
